import React from 'react'
import ProductCard from './ProductCard'
import { Link } from "react-router-dom";

function Collection(props) {
  const { products, title, description } = props;

  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
              <h2 className="text-xl font-bold text-gray-900 sm:text-3xl"> {title} </h2>
              <p className="mx-auto mt-4 max-w-md text-gray-500"> {description} </p>
          </header>
         
          <ul className="mt-8 flex justify-center flex-wrap">
            {products.map((product) => (
              <li key={product.productId} className="m-4">
                <Link key={product.productId} to={`/Shop/Shop All/${product.productId}`} className="group block overflow-hidden">
                  <ProductCard 
                    key={product.productId} 
                    name={product.productName} 
                    price={product.productPrice} 
                    image={product.productImages}
                    className="max-w-full max-h-full"
                  />
                </Link>
              </li>
            ))}
          </ul>

      </div>
    </section>
  )
}

export default Collection