import axios from "axios";
import { API_URL, BEARER, AUTH_TOKEN } from "../../constant";

export const CitiesData = async () => {
    let cities = [];
    try {
        const response = await axios.get(API_URL+"/shippings", {
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        cities = response.data.data.map((city) => {
            return {
                id: city.id,
                name: city.attributes.city_name,
                shippingPrice: city.attributes.shipping_price,
            }
        });
    } catch (error) {
        console.log(error);
    }
    return cities;
}