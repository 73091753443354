import React from 'react'

export default function Qodera() {
    return (
        <div className="p-4 text-center mb-3">
            <span className="flex items-center justify-center text-neutral-600">
                <a
                    className="text-neutral-600 hover:text-neutral-400 transition-colors duration-200"
                    href="https://www.linkedin.com/company/qodera"
                >
                    Qodera Software Solutions © 2024
                </a>  
            </span>
        </div>
    )
}