import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import { HomePage, ShopPage, AboutPage, ProductDetails, CheckoutPage, NotFound } from './index';
import { AnimatePresence } from 'framer-motion';

export default function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} exact />        
            <Route path="/Shop/:categoryName" element={<ShopPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/Shop/:categoryName/:productId" element={<ProductDetails />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path='*' element={<NotFound />}/>
        </Routes>
        </AnimatePresence>
  )
}
