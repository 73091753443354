import axios from "axios";
import { API_URL, BEARER, AUTH_TOKEN } from "../../constant";
import { UpdateQuantity } from "./UpdateQuantity";

export const SubmitOrder = async (userData, items, totalAmount) => {

  let Orders = [];
  
  items.forEach((item) => {
    Orders.push({
      Product_Name: item.name,
      Product_Color: item.color,
      Product_Size: item.size,
      Product_Quantity: item.quantity,
    });
  });

  const order = {
    Full_Name: userData.name,
    Email: userData.email,
    Phone_Number: userData.phone,
    Address: userData.address,
    Country: userData.country,
    City: userData.city,
    Total_Price: String(totalAmount),
    Orders: Orders,
  };

  try {
    await axios.post(API_URL + "/orders", { data: order }, {
        headers: {
            'Authorization': `${BEARER} ${AUTH_TOKEN}`
          }
    });
    for (let item of items) {
      const isUpdated = await UpdateQuantity(
        item.variant.variantId,
        item.quantity
      );
      if (!isUpdated) {
        return false;
      }
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
