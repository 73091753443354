import axios from 'axios';
import { API_URL, API_URL2, BEARER, AUTH_TOKEN } from '../../constant';

// get products by feature

export const GetBlogPictures = async (feature) => {
    let pictures = [];
    try {
        const res = await axios.get(API_URL+'/features?filters[name][$eq]='+feature+'&&populate=*',{
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        pictures = res.data.data[0].attributes.Pictures.data.map((item) => {
            return API_URL2+item.attributes.formats.large.url
        });
    } catch (err) {
        console.log(err);
        return
    }
    return pictures;
}

// get looks you love
// /features?filters[name][$eq]=Looks You'll Love&&populate=*