import React from 'react'
import { Link } from 'react-router-dom'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

function HeroSection() {
    const { width } = useWindowDimensions();
    const bgImage = width <= 768 ? "/HeroSection/1_Mobile.jpeg" : "/HeroSection/1.jpeg";

    return (
        <section style={{ backgroundImage: `url('${bgImage}')` }} className="h-screen bg-cover">
            <div className="h-screen bg-opacity-50 bg-black flex items-center justify-center" style={{ background: "rgba(0,0,0,0.5)" }}>
                <div className="mx-2 text-center">
                    <h1 className="text-gray-100 font-extrabold text-4xl xs:text-5xl md:text-6xl">
                        <span className="text-white">Welcome to</span> Saran
                    </h1>
                    <h2 className="text-gray-200 font-extrabold text-3xl xs:text-4xl md:text-5xl leading-tight">
                        Elevate Your Style with Modesty
                    </h2>
                    <div className="inline-flex flex-grow justify-end">
                        <Link to={'/Shop/Shop All'} className="p-4 my-8 mx-4 bg-maincolor hover:bg-secondarycolor text-xxs md:text-lg uppercase tracking-wide text-white shadow-md transition duration-500">                        
                            Shop Now
                        </Link>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default HeroSection