import React, { useEffect } from 'react'
import ProductCard from '../components/Product/ProductCard'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { GetProductsByCategory } from '../helper/GetProducts'
import { GetCategoryByName } from '../helper/GetCategories'
import { motion } from 'framer-motion'

function ShopPage() {
  const [isListView, setIsListView] = useState(false);
  const [sortOrder, setSortOrder] = useState('default');
  const handleViewChange = () => setIsListView(!isListView);
  const handleSortChange = (event) => setSortOrder(event.target.value);
  const [currentCategory, setCurrentCategory] = useState([]);

  const [products, setProducts] = useState([]);

  let { categoryName } = useParams();
  categoryName = categoryName.replace(/-/g, ' ');

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await GetProductsByCategory(categoryName);
      setProducts(products);
    }
    fetchProducts();
  }, [categoryName]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await GetCategoryByName(categoryName);
      setCurrentCategory(categories);
    }
    fetchCategories();
  }, [categoryName]);

  let sortedProducts = [];
  switch (sortOrder) {
    case 'price-asc':
      sortedProducts = products.sort((a, b) => a.productPrice - b.productPrice);
      break;
    case 'price-desc':
      sortedProducts = products.sort((a, b) => b.productPrice - a.productPrice);
      break;
    case 'name-asc':
      sortedProducts = products.sort((a, b) => a.productName.localeCompare(b.productName));
      break;
    case 'name-desc':
      sortedProducts = products.sort((a, b) => b.productName.localeCompare(a.productName));
      break;
    default:
      sortedProducts = products;
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
        
        <header>
          <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
            { currentCategory.title }
          </h2>
          <p className="mt-4 max-w-md text-gray-500">
            { currentCategory.description }
          </p>
        </header>

        <div className="mt-8 flex items-center justify-between">
          <div className="flex rounded border border-gray-100">
            <button onClick={handleViewChange} className="inline-flex h-10 w-10 items-center justify-center border-e text-gray-600 transition hover:bg-gray-50 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"/>
              </svg>
            </button>
            <button onClick={handleViewChange} className="inline-flex h-10 w-10 items-center justify-center text-gray-600 transition hover:bg-gray-50 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"/>
              </svg>
            </button>
          </div>

          <div>
            <label htmlFor="SortBy" className="sr-only">
              SortBy
            </label>
            <select value={sortOrder} onChange={handleSortChange}>
              <option key="default" value="default">Default</option>
              <option key="price-asc" value="price-asc">Price (Low to High)</option>
              <option key="price-desc" value="price-desc">Price (High to Low)</option>
              <option key="name-asc" value="name-asc">Name (A to Z)</option>
              <option key="name-desc" value="name-desc">Name (Z to A)</option>
            </select>
          </div>
        </div>

        <ul className={`mt-4 ${isListView ? 'flex flex-col gap-4 w-full' : 'grid gap-4 sm:grid-cols-2 lg:grid-cols-4'}`}>
          {sortedProducts.map((product) => {
            return (
              <Link key={product.productId} to={`/Shop/${categoryName}/${product.productId}`} className="group block overflow-hidden">
                <ProductCard
                  key={product.productId}
                  name={product.productName}
                  price={product.productPrice}
                  image={product.productImages && product.productImages[0]}
                />
              </Link>
            );
          })}
        </ul>

      </div>
    </motion.div>

  )
}

export default ShopPage