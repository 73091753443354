import React from 'react'

export default function Price(props) {
    let oldPrice = props.oldPrice
    let newPrice = props.newPrice  
    return (
        <p className="inline-block text-2xl font-semibold text-gray-700">
            <span className='m-0'>{newPrice} EGP</span>
            { oldPrice && 
            <span className="text-base font-normal text-gray-500 line-through ">
                {oldPrice} EGP
            </span>
            }
        </p>
    )
}
