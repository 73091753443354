/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useMemo, useState, useEffect} from 'react'
import OrderSummaryCard from '../components/Checkout/OrderSummaryCard'
import { useCart } from "react-use-cart";
import { motion } from 'framer-motion'
import { useAlert } from 'react-alert';
import { handleCheckout } from '../helper/Checkout/HandleCheckout';
import { CitiesData } from '../components/Checkout/CitiesData';
import CheckoutForm from '../components/Checkout/CheckoutForm';

export default function Checkout() {

  const alert = useAlert()
  const {items, removeItem, cartTotal,  isEmpty, emptyCart} = useCart();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("Egypt");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);

  const [shippingPrice, setShippingPrice] = useState(0);

  // Calculate total price including shipping
  const totalPrice = useMemo(() => {
    if (selectedCity) {
      const city = cities.find((city) => city.name === selectedCity);
      setShippingPrice(city.shippingPrice);
      return cartTotal + city.shippingPrice;
    }
    return cartTotal;
  }, [selectedCity, cartTotal, cities]);

  const [isSubmitting, setIsSubmitting] = useState(false); // To prevent multiple submissions

  // Get cities data from the backend
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const cities = await CitiesData();
        setCities(cities);
      } catch (error) {
        alert.show('Failed to fetch cities data. Please try again later.');
      }
    }
    fetchCities();
  }, []);

  // Handle checkout event
  const handleCheckoutEvent = useCallback((event) => {
    event.preventDefault();

    // Form validation
    if (!name || !email || !phone || !address || !selectedCity) {
      alert.show('Please fill in all fields', { type: 'error' });
      return;
    }

    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      alert.show('Invalid email format', { type: 'error' });
      return;
    }

    if (!/^\d{11}$/.test(phone)) {
      alert.show('Invalid phone number format', { type: 'error' });
      return;
    }

    const userData = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      country: selectedCountry,
      city: selectedCity,
    };

    handleCheckout(userData, items, totalPrice, shippingPrice, alert, emptyCart, setIsSubmitting);
  }, [name, email, phone, address, selectedCountry, selectedCity, items, totalPrice, shippingPrice, alert, emptyCart, setIsSubmitting]);


  return (
    <motion.div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>

      <CheckoutForm 
        name={name} setName={setName}
        email={email} setEmail={setEmail}
        phone={phone} setPhone={setPhone}
        address={address} setAddress={setAddress}
        selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
        selectedCity={selectedCity} setSelectedCity={setSelectedCity}
        cities={cities}
        cartTotal={cartTotal} shippingPrice={shippingPrice} totalPrice={totalPrice}
        isEmpty={isEmpty} isSubmitting={isSubmitting}
        handleCheckoutEvent={handleCheckoutEvent}
      />

      <div className="px-4 pt-8">
        <p className="text-xl font-medium">Order Summary</p>
        <p className="text-gray-400">
          Check your items.
        </p>
        <div className="mt-2 rounded-lg bg-white px-2 py-4 sm:px-6"> 
            { items.map((item) => {
              return (
                <OrderSummaryCard 
                  key={item.id}
                  item={item}
                  removeItem={removeItem}
                />
              );
            })
            }
        </div>
      </div>
      

    </motion.div>

  )
}
