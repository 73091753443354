import axios from 'axios';
import { API_URL, BEARER, AUTH_TOKEN } from '../constant';

export async function GetCategories() {
  let categories = [];
  try {
    const res = await axios.get(API_URL + '/Categories',{
      headers: {
          'Authorization': `${BEARER} ${AUTH_TOKEN}`
        }
    });
    categories = res.data.data.map((item) => {
      return {
        title: item.attributes.name,
        path: '/Shop/' + item.attributes.name.replace(/\s+/g, '-'),
        cName: 'nav-text'
      }
    });
  } catch (err) {
    console.log(err);
  }
  return categories;
}

// get category by name
export async function GetCategoryByName(name) {
  let category = {};
  try {
    const res = await axios.get(API_URL + '/categories?filters[name][$eq]=' + name + '&populate=*', {
        headers: {
            'Authorization': `${BEARER} ${AUTH_TOKEN}`
          }
    });
    category = {
      title: res.data.data[0].attributes.name,
      description: res.data.data[0].attributes.description,
      // products: res.data.data[0].products.data.map((product) => {
      //   return {
      //     productId: product.id,
      //     productName: product.attributes.name,
      //     productPrice: product.attributes.price,
      //     productImage: product.attributes.image.url
      //   }
      // }
      // )
    }
  } catch (err) {
    console.log(err);
  }
  return category;
}