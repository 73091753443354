import React from 'react'
import ProductCard from './ProductCard'
import { Link } from "react-router-dom";

function Blog(props) {
  const { pictures, title, description,  } = props;
  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
              <h2 className="text-xl font-bold text-gray-900 sm:text-3xl"> {title} </h2>
              <p className="mx-auto mt-4 max-w-md text-gray-500"> {description} </p>
          </header>
         
          <ul className="mt-8 flex flex-wrap justify-center">
            {pictures.map((picture, index) => (
              <li key={index} className="m-4">
                <Link key={index} className="group block overflow-hidden">
                  <ProductCard 
                    key={index}
                    image={picture}
                  />
                </Link>
              </li>
            ))}
          </ul>

      </div>
    </section>
  )
}

export default Blog