import { Link } from "react-router-dom";

export default function ProductCard(props) {
  const { name, price, image } = props;
  return (
    <>
        <img
          src={image}
          alt=""
          className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
        />
        <div className="relative pt-3">
         {name && <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
            {name}
          </h3>}
          <p className="mt-2">
            <span className="sr-only"> Regular Price </span>
            { price && <span className="tracking-wider text-gray-900"> {price} EGP </span>}
          </p>
        </div>
    </>
  );
}
