import React from "react";

const InputField = ({ label, type, value, onChange, placeholder, id, required }) => (
    <div className="mt-2">
        <label className="sr-only" htmlFor={id}>{label}</label>
        <input
            className="w-full rounded-lg border-maincolor p-3 text-sm"
            type={type}
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
        />
    </div>
);

export default InputField;