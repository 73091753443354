import React from 'react'

function WhoAreWe() {
  return (
    <section>
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-3xl">
        <h2 className="text-3xl font-bold sm:text-4xl">
            Who Are We ?
        </h2>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
        <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
            alt="Party"
            src='/AboutUs/AboutUs_3.jpg'
            className="absolute inset-0 h-full w-full object-cover"
            />
        </div>
        <div className="lg:py-16">
            <article className="space-y-4 text-gray-600">
            <p>
            Welcome to SARAN, where fashion meets individuality. SARAN is an Egyptian brand founded in 2022. We believe that clothing is not just about style, but also about expressing your unique personality. 
            </p>
            <p>
                Our carefully curated collections are passionately designed to empower you to embrace your personal style and celebrate your uniqueness. We aim to always bring you high-quality clothing that suits everyday style, passionately hand-picking the best materials and finishing for your piece. Join us on a journey of self-expression through fashion eith SARAN.
            </p>
            </article>
        </div>
        </div>
    </div>
    </section>

  )
}

export default WhoAreWe