import axios from 'axios';
import { API_URL, BEARER, AUTH_TOKEN } from '../constant';

export async function isNewArrival(product_name) {
    let newProduct = false;
    try {
        const res = await axios.get(API_URL + '/Categories?filters[name][$eq]=New Arrivals&populate=*',{
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        // check if the product is in the new arrival category
        const new_arrival_category = res.data.data[0];
        const new_arrival_products = new_arrival_category.attributes.products.data.map((product) => {
            return product.attributes.name;
        });
        newProduct = new_arrival_products.includes(product_name);
    }
    catch (err) {
        console.log(err);
    }

    return newProduct;
}
