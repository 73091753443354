// useAnnouncement.js
import { useState, useEffect } from 'react';

const useAnnouncement = (API_URL) => {
  const [announcementText, setAnnouncementText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/constants?filters[Name][$eq]=Announcement`);
        const data = await response.json();
        if (data.data[0].attributes.Text_Value){
          let announcement = data.data[0].attributes.Text_Value.toString();
          setAnnouncementText(announcement);
        }
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    }
    fetchAnnouncement();
  }, [API_URL]);

  return { announcementText, isLoading, error };
}

export default useAnnouncement;