import React from 'react'
import { Link } from 'react-router-dom'

function NewCollection() {
  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
        <header className="text-center">
          <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
            Our Collections
          </h2>
          <p className="mx-auto mt-4 max-w-md text-gray-500">
            Explore unique worlds of curated items - discover your next obsession in Our Collections. ✨
          </p>
        </header>
        <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
          <li>
            <Link to={'/Shop/Evening'} href="#" className="group relative block">
              <img
                src="/Collections/Evening.jpeg"
                alt=""
                className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
              />
              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <h3 className="text-xl font-medium text-white"> Evening </h3>
                <button to={'/Shop/Kimonos-and-Cardigans'} className="mt-1.5 inline-block bg-maincolor px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  Shop Now
                </button>
              </div>
            </Link>
          </li>
          <li>
            <Link to={'/Shop/Shirts-and-Blouses'} className="group relative block">
              <img
                src="/Collections/Shirts.jpeg"
                alt=""
                className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
              />
              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <h3 className="text-xl font-medium text-white"> Shirts </h3>
                <button to={'/Shop/Shirts-and-Blouses'} className="mt-1.5 inline-block bg-maincolor px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  Shop Now
                </button>
              </div>
            </Link>
          </li>
          <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
            <Link to={'/Shop/Dresses'} className="group relative block">
              <img
                src="/Collections/Dresses.jpg"
                alt=""
                className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
              />
              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <h3 className="text-xl font-medium text-white">
                  Dresses
                </h3>
                <button to={'/Shop/Dresses'} className="mt-1.5 inline-block bg-maincolor px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  Shop Now
                </button>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default NewCollection