/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function Thumbnail(props) {
    const url = props.url;
    const handleClick = props.handleClick;
    return (
        <div className="p-2 justify-center items-center">
            <a onClick={handleClick} className="block border-1 border-secondarycolor hover:border-maincolor 
            transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 overflow-hidden
             ">
              <img
                src={url}
                alt=""
                className="object-cover w-12 h-20 lg:h-20 "
              />
            </a>
        </div>
    )
} 