import React from 'react';

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState({
        width: window.innerWidth,
    });

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export { useWindowDimensions };