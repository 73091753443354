import React, {useState, useEffect} from 'react'
import { GetCategories } from "../../../helper/GetCategories";
import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function Sidebar({sidebar, showSidebar}) {
    const [SidebarData, setSidebarData] = useState([]);
    useEffect(() => {
        const fetchCategories = async () => {
        const categories = await GetCategories();
        setSidebarData(categories);
        }
        fetchCategories();
    }, []);
    return (
        <div className={`nav-menu ${sidebar ? 'active' : ''}`}>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
                <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle flex absolute left-0">
                    <Link to="#" className="menu-bars">
                    <AiIcons.AiOutlineClose color="#914e3d" />
                    </Link>
                </li>
                {SidebarData.map((item, index) => {
                    return (
                    <li key={index} className={item.cName}>
                        <Link to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                        </Link>
                    </li>
                    );
                })}
                </ul>
            </nav>
        </div>
  )
}
