import React from 'react'

export default function SizeChart(props) {
  const { url } = props;
  return (
    <div className="mt-8 mb-10 mr-4 lg:mb-0">
      <details className="text-sm">
      <summary className="mb-2 text-xl font-bold">Size Chart</summary>
      <img src={url} alt="" className="w-full" />
      </details>
    </div>
  )
}
