import React from 'react'

export default function QuantityChoice(props) {
    const {decreaseQuantity, selectedQuantity, handleQuantityChange, maxQuantity, increaseQuantity} = props;
    return (
        <div className="mb-4 mr-4 lg:mb-0">
        <div className="w-28">
        {/* handleQuantityChange */}
        <div className="relative flex flex-row w-full h-10">
          <button onClick={decreaseQuantity} className="w-20 h-full text-gray-600 bg-maincolor border-r outline-none cursor-pointer hover:text-gray-700 hover:bg-secondarycolor">
              <span className="m-auto text-2xl font-light text-white">-</span>
          </button>
            <input
                type="number"
                className="flex items-center w-full font-normal text-center text-gray-700 bg-gray-100 outline-none focus:outline-none text-md hover:text-black"
                value={selectedQuantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
                max={maxQuantity}
                readOnly
            />
          <button onClick={increaseQuantity} className="w-20 h-full text-gray-600 bg-maincolor border-l outline-none cursor-pointer hover:text-gray-700 hover:bg-secondarycolor">
              <span className="m-auto text-2xl font-light text-white">+</span>
          </button>
          </div>
        </div>
      </div>
    )
}
