import axios from 'axios';
import { API_URL, API_URL2, AUTH_TOKEN, BEARER } from '../constant';

export async function GetProductsByCategory(category) {
    let products = [];
    try {
        const res = await axios.get(API_URL + '/Products?filters[categories][name][$eq]=' + category + '&populate=*',{
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        products = res.data.data.map((item) => {
        return {
            productId: item.id,
            productName: item.attributes.name.replace(/-/g, ' '),
            productPrice: item.attributes.price,
            productImages: item.attributes.images.data ? item.attributes.images.data.map((image) => {
                return API_URL2+image.attributes.formats.large.url;
            }) : [],
        }
        });
    } catch (err) {
        console.log(err);
    }
  return products;
}

export async function GetProductById(id){
    let product = {};
    try {
        const res = await axios.get(API_URL + '/Products/' + id + '?populate=*', {
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        product = {
            productId: res.data.data.id,
            productName: res.data.data.attributes.name,
            productDescription: res.data.data.attributes.description,
            productPrice: res.data.data.attributes.price,
            productOldPrice: res.data.data.attributes.oldPrice,
            // array of image urls,
            productImages: res.data.data.attributes.images.data.map((image) => {
                return API_URL2+image.attributes.formats.large.url;
            }
            ),
            productThumbnail: res.data.data.attributes.images.data.map((image) => {
                return API_URL2+image.attributes.formats.thumbnail.url;
            }
            ),
            productCategory: res.data.data.attributes.categories.data.map((category) => {
                return category.attributes.name;
            }),
            productVariants: res.data.data.attributes.variants.data.map((variant) => {
                return {
                    variantId: variant.id,
                    variantColor: variant.attributes.color,
                    variantSize: variant.attributes.size,
                    variantQuantity: variant.attributes.quantity
                }
            }),
            productSizeChart: res.data.data.attributes.SizeChart.data ? API_URL2+res.data.data.attributes.SizeChart.data.attributes.formats.large.url : null,
        }
    } catch (err) {
        console.log(err);
    }
    return product;
}