import React from 'react'

function Divider() {
  return (
    <>
        <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
    </>

  )
}

export default Divider