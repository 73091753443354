import SendEmailToAdmin from "../Email/SendEmailToAdmin";
import SendEmailToCustomer from "../Email/SendEmailToCustomer";
import { SubmitOrder } from "./SubmitOrder";

export const handleCheckout = async (
  userData,
  items,
  totalAmount,
  shippingPrice,
  alert,
  emptyCart,
  setIsSubmitting,
) => {
  console.log(totalAmount);
  setIsSubmitting(true);
  try {
    const isSuccessful = await SubmitOrder(userData, items, totalAmount);
    if (isSuccessful) {
      try {
        await Promise.all([
          SendEmailToAdmin(items, totalAmount, shippingPrice, userData),
          SendEmailToCustomer(userData.email, items, totalAmount, shippingPrice)
        ]);
        
        alert.show("Order submitted successfully", {
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/";
          emptyCart();
        }, 2500); // Use this instead of window.location.href = "/";
      } catch (error) {
        console.error(error);
      }
    } else {
      alert.show("Failed to submit order", {
        type: "error",
      });
    }
  } catch (error) {
    console.error(error);
    // Handle error
  } finally {
    setIsSubmitting(false);
  }
};