import React from 'react'

function Announcement(props) {
  const { announcement } = props;
  return (
    <div className="bg-maincolor py-3 text-white">
        <p className="text-center text-sm">
            {announcement}
        </p>
    </div>

  )
}

export default Announcement