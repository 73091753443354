import React, { useState, useEffect } from 'react'
import Thumbnail from '../components/ProductDetails/Thumbnail'
import Price from '../components/ProductDetails/Price'
import { GetProductById } from '../helper/GetProducts'
import { isNewArrival } from '../helper/isNewArrival';
import { useCart } from 'react-use-cart';
import BuyNowButton from '../components/ProductDetails/Buttons/BuyNowButton';
import AddToCartButton from '../components/ProductDetails/Buttons/AddToCartButton';
import AddToCartButtonGreyed from '../components/ProductDetails/Buttons/AddToCartButtonGreyed';
import BuyNowButtonGreyed from '../components/ProductDetails/Buttons/BuyNowButtonGreyed';
import QuantityChoice from '../components/ProductDetails/QuantityChoice';
import SizeChart from '../components/ProductDetails/SizeChart';
import { useAlert } from 'react-alert'
import { motion } from 'framer-motion'

function ProductDetails() {
  const [product, setProduct] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [maxQuantity, setMaxQuantity] = useState(0);
  const alert = useAlert()

  const { items, addItem } = useCart();

  let id = window.location.pathname.split('/').pop(); 

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    if (id !== 'checkout') {
      const fetchProduct = async () => {
        const product = await GetProductById(id);
        setProduct(product);
        // set the first selected variant with quantity > 0
        const firstVariant = product.productVariants && product.productVariants.find(variant => variant.variantQuantity > 0);
        if (firstVariant) {
          setSelectedVariant(firstVariant);
          setSelectedColor(firstVariant.variantColor);
          setSelectedSize(firstVariant.variantSize);
          setMaxQuantity(firstVariant.variantQuantity);
        } else {
          setSelectedVariant({});
          setSelectedColor('');
          setSelectedSize('');
          setMaxQuantity(0);
        }
      }
      fetchProduct();
    }
  }, [id]);
  
  const handleColorChange = (event) => setSelectedColor(event.target.value); 
  const handleSizeChange = (event) => setSelectedSize(event.target.value);

  let availableSizes = [];
  const handleQuantityChange = (event) => setSelectedQuantity(event.target.value);
  const increaseQuantity = () => setSelectedQuantity(selectedQuantity < maxQuantity ? selectedQuantity + 1 : maxQuantity);
  const decreaseQuantity = () => selectedQuantity > 1 ? setSelectedQuantity(selectedQuantity - 1) : '';
  if (selectedColor) {
    availableSizes = product.productVariants.filter(variant => variant.variantColor === selectedColor && variant.variantQuantity > 0).map(variant => variant.variantSize);
  }
  
  useEffect(() => {
    const fetchNewArrival = async () => {
      const isNew = await isNewArrival(product.productName);
      setIsNew(isNew);
    }
    fetchNewArrival();
  }
  , [product.productName]);

  useEffect(() => {
    const variant = product.productVariants && product.productVariants.find(variant => variant.variantColor === selectedColor && variant.variantSize === selectedSize && variant.variantQuantity > 0);
    if (variant){
      setSelectedVariant(variant);
      setMaxQuantity(variant.variantQuantity);
    }
    else {
      setSelectedVariant(false);
      setMaxQuantity(0);
    }
  }, [selectedColor, selectedSize]);

  const checkIfProductInCart = (showAlert) => {
    const productInCart = items.find(item => item.id === product.productId && item.color === selectedColor && item.size === selectedSize);
    if (productInCart) {
      if (productInCart.quantity + selectedQuantity > maxQuantity && showAlert) {
        alert.show("Sorry, Insufficient Stock", {type: 'error'});
        return true;
      }
      else if (productInCart.quantity + selectedQuantity > maxQuantity && !showAlert) {
        return true;
      }
    }
    return false;
  }


  const handleBuyNow = () => {
    const quantityExceeds = checkIfProductInCart(false);
    if (quantityExceeds) {
      return;
    }
    const prod = {
      id: product.productId,
      name: product.productName,
      price: product.productPrice,
      color: selectedColor,
      size: selectedSize,
      img: product.productThumbnail[0],
      variant: selectedVariant
    }
    addItem(prod, selectedQuantity);    
    alert.show(`${selectedQuantity}x ${prod.name} added to cart`, {type: 'success'});
  } 

  const handleAddToCart = () => {
    const quantityExceeds = checkIfProductInCart(true);
    if (quantityExceeds) {
      return;
    }
    const prod = {
      id: product.productId,
      name: product.productName,
      price: product.productPrice,
      color: selectedColor,
      size: selectedSize,
      img: product.productThumbnail[0],
      variant: selectedVariant
    }
    addItem(prod, selectedQuantity);
    alert.show(`${selectedQuantity}x ${prod.name} added to cart`, {type: 'success'});
  }

  const changeImage = (image) => {
    let imgElement = document.querySelector('.object-contain');
    imgElement.style.opacity = 0;
    setTimeout(() => {
      imgElement.src = image;
      imgElement.style.opacity = 1;
    }, 300);
  }


  return (
    <motion.div className="py-4 overflow-hidden lg:py-20" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="max-w-6xl px-4 mx-auto lg:py-8 md:px-6">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 md:w-1/2 ">
            <div className="sticky top-0 z-50 overflow-hidden ">
              <div className="relative mb-6 w-full h-full lg:mb-10">
                <img
                  src={product.productImages && product.productImages[0]}
                  alt=""
                  className="object-contain w-full h-full image-transition"
                />
              </div>
              <div className="flex flex-wrap justify-center mx-auto">
                {product.productImages && product.productThumbnail.map((image, index) => (
                  <Thumbnail
                    key={index}
                    url={image}
                    handleClick={() => changeImage(product.productImages[index])}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 ">
            <div className="lg:pl-20">
              <div className="pb-2 mb-4 border-b border-gray-200">
                {isNew && <p className="text-2xl font-bold text-black">New</p>}
                {
                product.productVariants && product.productVariants.some(variant => variant.variantQuantity > 0) ?
                 <p className="text-lg mb-4font-medium text-green-600">
                    In Stock
                  </p>
                  :
                  <p className="text-lg mb-4 font-medium text-rose-600">
                    Out of Stock
                  </p>
                }
                <h2 className="max-w-xl mt-2 mb-4 text-xl font-bold md:text-4xl">
                  {product.productName}
                </h2>
                <p className="max-w-md mb-4 text-gray-700">
                  {product.productDescription}
                </p>
                <Price oldPrice={product.productOldPrice} newPrice={product.productPrice} />
              </div>

              {/* Choose Color */}
              <div className="mb-8">
                <h2 className="mb-2 text-xl font-bold">Availlable Colors</h2>
                <div className="flex flex-wrap -mb-2">
                  {product.productVariants && 
                    [...new Set(product.productVariants.map(item => item.variantColor))].map((variantColor) => (
                    <label
                      key={variantColor}
                      htmlFor={variantColor}
                      className={`px-3 py-2 mb-2 mr-2 border w-1/8 hover:border-maincolor hover:text-maincolor ${
                        selectedColor === variantColor ? 'border-maincolor text-maincolor' : ''
                      }`}
                    >
                      <input
                        type="radio"
                        id={variantColor}
                        name="color"
                        value={variantColor}
                        checked={selectedColor === variantColor}
                        onChange={handleColorChange}
                        className="hidden"
                      />
                      {variantColor}
                    </label>
                  ))}
                </div>
              </div>

              {/* Choose Size */}
              <div className="pb-6 mb-8 border-b border-gray-300">
                <h2 className="mb-2 text-xl font-bold">Availlable Sizes</h2>
                <div className="flex flex-wrap -mb-2">
                {
                  availableSizes.length > 0 ?
                  availableSizes.map((variantSize) => (
                    <label
                      key={variantSize}
                      htmlFor={variantSize}
                      className={`px-3 py-2 mb-2 mr-2 border w-1/8 hover:border-maincolor hover:text-maincolor ${
                        selectedSize === variantSize ? 'border-maincolor text-maincolor' : ''
                      }`}
                    >
                      <input
                        type="radio"
                        id={variantSize}
                        name="size"
                        value={variantSize}
                        checked={selectedSize === variantSize}
                        onChange={handleSizeChange}
                        className="hidden"
                      />
                      {variantSize}
                    </label>
                  ))
                  :
                  <label
                     className="px-3 py-2 mb-2 mr-2 border w-1/8 border-gray-400 text-gray-400">
                      No Sizes Availlable
                    </label>
                }
                </div>

                
                {product.productSizeChart && <SizeChart url={product.productSizeChart} />}
                

                <div className="mt-8 mb-10 mr-4 lg:mb-0">
                  <details className="text-sm">
                  <summary className="mb-2 text-xl font-bold">Refund Policy</summary>
                  <p className="text-gray-600  mt-2 text-lg">
                      You can check the items you ordered while courier is waiting and if you don’t want it or it doesn’t fit give it back to the courier and kindly only pay shipping fees. 
                      PLEASE NOTE that there’s no refund after letting courier leave. 
                      Thanks and enjoy your purchase. 🤍
                  </p>
                  </details>
                </div>
              </div>
             

            <div className="flex flex-wrap items-center ">
              <QuantityChoice 
                  decreaseQuantity = {decreaseQuantity}
                  selectedQuantity = {selectedQuantity}
                  handleQuantityChange = {handleQuantityChange}
                  maxQuantity = {maxQuantity}
                  increaseQuantity = {increaseQuantity}
                />

                <div className="mb-4 mr-4 lg:mb-0">
                { selectedVariant.variantQuantity > 0 ? 
                  <BuyNowButton handleBuyNow={handleBuyNow} />
                    :
                  <BuyNowButtonGreyed />
                }
                </div>
                <div className="mb-4 mr-4 lg:mb-0">
                { 
                  selectedVariant.variantQuantity > 0 ? 
                    <AddToCartButton handleAddToCart={handleAddToCart} />
                    :
                    <AddToCartButtonGreyed />
                }
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  
  
  )
}

export default ProductDetails