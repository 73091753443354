import axios from 'axios';
import { API_URL, BEARER, AUTH_TOKEN } from '../../constant';

export const UpdateQuantity = async (variantID, quantity) => {
    try {
        const response = await axios.get(API_URL+'/variants?filters[id][$eq]='+variantID+'&&populate=*',{
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        let variant = response.data.data[0];
        if (variant.attributes.quantity < quantity) {
            return false;
        }
        let newQuantity = variant.attributes.quantity - quantity;
        await axios.put(API_URL+'/variants/'+variantID, {
            data: {
                "quantity": newQuantity
            }
        },{
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}