import React from 'react'
import WhoAreWe from '../components/AboutUs/WhoAreWe'
import ContactUs from '../components/AboutUs/ContactUs'
import { motion } from 'framer-motion'

function AboutPage() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
    <WhoAreWe />
    <ContactUs />  
    </motion.div>
  )
}

export default AboutPage