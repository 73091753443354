import axios from 'axios';
import { API_URL, API_URL2, BEARER, AUTH_TOKEN } from '../../constant';

// get products by feature

export const GetProductsByFeature = async (feature) => {
    let products = [];
    try {
        const res = await axios.get(API_URL+'/Products?filters[features][name][$eq]='+feature+'&&populate=*',{
            headers: {
                'Authorization': `${BEARER} ${AUTH_TOKEN}`
              }
        });
        products = res.data.data.map((item) => {
            return {
                productId: item.id,
                productName: item.attributes.name.replace(/-/g, ' '),
                productPrice: item.attributes.price,
                productImages: item.attributes.images.data ? API_URL2+item.attributes.images.data[0].attributes.formats.large.url : '',
            }
        });
    } catch (err) {
        console.log(err);
    }
    return products;
}