import React, { useEffect, useState } from 'react'
import HeroSection from '../components/HeroSections/HeroSection'
import Collection from '../components/Product/Collection'
import NewCollection from '../components/Product/NewCollection'
import Divider from '../components/Others/Divider'
import OverlapSection from '../components/AboutUs/OverlapSection'
import { motion } from 'framer-motion'
import { GetProductsByFeature } from '../helper/Homepage/GetProductsByFeature'
import Blog from '../components/Product/Blog'
import { GetBlogPictures } from '../helper/Homepage/GetBlogPictures'
import { useAlert } from 'react-alert'

function HomePage() {
  const [data, setData] = useState({ justDropped: [], blogPictures: [] });
  const alert = useAlert();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const justDropped = await GetProductsByFeature('Just Dropped');
        const blogPictures = await GetBlogPictures("Looks You'll Love");
        setData({ justDropped, blogPictures });
      } catch (error) {
        alert.show('Failed to fetch products. Please try again later.', { type: 'error' });
      }
    }
    fetchProducts();
  }, []);

  return (
    <motion.div  initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <HeroSection />
        <Collection products={data.justDropped} title={'Just Dropped'} 
          description={"Hot off the press! Shop the freshest finds in Just Dropped. ✨"} />
        <Divider />
        <NewCollection />       
        {data.blogPictures && <>
        <Divider />
        <Blog pictures={data.blogPictures} title={"Looks You'll Love"} description={"Unleash your inner stylist with Looks You'll Love! Find curated outfits for every occasion, tailored to your style. Feel amazing, look amazing, be amazing. ✨"} />
        </>}
        <Divider />
        <OverlapSection  />
    </motion.div>
  )
}

export default HomePage